import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { Row, Col } from 'bootstrap-4-react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

import { useNavigate } from "react-router-dom";

// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

// import required modules
import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper/modules';

import * as apiUrl from '../apiUrl';
import * as imgUrl from '../apiUrl';
import LogoImage from './../../src/asserts/img/logo.png';
const ProjectsLayout = () => {

    const navigate = useNavigate();

    const [projects, setProjects] = useState([]);    

    useEffect(() => {

        fetch(apiUrl.apiUrl + '/api/all-projects')
            .then(response => response.json())
            .then(data => {
                // console.log(data); // Log the response data to the console
                setProjects(data);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    return (

        <main className='page-content'>
                <div className='center-common-heading'>
                    <Row className='dropdown-row'>
                        <Col md={2}>
                            <div className='plot-app-logo'>
                                <img src={LogoImage} className='img-fluid' />
                            </div>
                        </Col>
                        {/* <Col md={4}> <h4>Projects</h4></Col> */}
                        <Col md={4} className="drop-down-left">
                        <Dropdown data-bs-theme="dark">
        <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
        Projects
        </Dropdown.Toggle>

        <Dropdown.Menu>
      
          <Dropdown.Item href="#/action-2">New Vision Township</Dropdown.Item>
          <Dropdown.Item href="#/action-3">Sameera Iris</Dropdown.Item>
   
          <Dropdown.Item href="#/action-4">Sameera Vajaralayam</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
                        </Col>
                    </Row>
                   
                </div>
            <div className='flex-wrap-new'>
                {/* <div className='amenities-div'>
                    <div className='sidebar-left-title'>
                        <h4>Projects</h4>
                    </div>
                </div> */}
        
                <div className='block-img-div-new'>
                    <div className='block-img project-swiper'>
                        <Swiper className="projects-swiper"
                            spaceBetween={30}
                            slidesPerView={1}
                            grabCursor={true}
                            preventClicks={true}
                            draggable={true}
                            speed={500}
                            modules={[Navigation, Pagination, EffectFade, Autoplay]}
                            navigation={true}
                            pagination={false}
                            autoplay={false} 
                        >
                            {projects.map(project => (
                            <SwiperSlide key={project.id}>                           
                                <div className='project-img'>
                                    <img src={imgUrl.imgUrl + `/storage/projects/${project.project_image_file}`} alt={project.project_name} />
                                </div>
                                <div className='project-content'>
                                    <img src={imgUrl.imgUrl + `/storage/projects/${project.logo_file}`} className='img-fluid' />
                                    <h2>{project.project_name}</h2>
                                    <h6> <FontAwesomeIcon icon={faMapMarkerAlt} /> <span>Location Name</span></h6>
                                    <Button className='proceed-button' id={project.id} onClick={() => navigate(`/location/${project.id}`)}>Choose Your Units</Button>
                                </div>                                
                            </SwiperSlide>
                             ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </main>        
        );
}

export default ProjectsLayout;
